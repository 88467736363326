<template>
  <div>
    <BaseForm
      :title="title"
      :mod="mod"
      :flat="flat"
      :divider="divider"
      :dialog="dialog"
      :loading="loading"
      @save="save"
    >
      <template #content>
        <div class="pa-4">
          <v-text-field
            label="Nome do plano"
            v-model="form.name"
          ></v-text-field>
        </div>

        <div class="pa-4">
          <PriceField label="Preço do plano" v-model="form.value" />
        </div>

        <h3 class="pa-4 mt-2">Features do plano</h3>

        <v-list-item-group multiple>
          <v-list-item :key="feature.id" v-for="feature in features">
            <v-list-item-action>
              <v-checkbox
                v-model="form.selectedFeatures"
                :value="feature.id"
              ></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ feature.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </template>

      <template #actions></template>
    </BaseForm>
  </div>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import BaseForm from "@/components/template/BaseForm";
import errorHandler from "@/helpers/error_handler";
import moneyHelper from "@/helpers/money_helper";
import PriceField from "@/components/template/FormElements/PriceField";

export default {
  components: {
    BaseForm,
    PriceField,
  },
  mixins: [formMixin],
  data: () => ({
    loading: false,
    loadingFeatures: false,
    form: { selectedFeatures: [], value: "0.0" },
    panel: 0,
    plan: null,
    features: [],
    actionAfterOperation: null,
  }),
  computed: {},
  methods: {
    validate() {
      const errors = [];

      if (!this.form.name) errors.push("Informe o nome do plano de anúncio");

      if (!this.form.value) errors.push("Informe o preço do plano de anúncio");

      if (!this.form.selectedFeatures.length)
        errors.push("Selecione pelo menos uma feature para o plano de anúncio");

      return errors;
    },
    async save(actionAfterOperation) {
      if (
        !(await this.$root.$confirm("Confirmação", "Salvar dados?", {
          color: "primary",
        }))
      )
        return;

      const errors = this.validate();

      if (errors.length) {
        this.$root.$errorDialog(errors);
      } else {
        this.actionAfterOperation = actionAfterOperation;
        this[this.mod]();
      }
    },
    async insert() {
      try {
        this.loading = true;

        const dataToSend = this.prepareDataTosend(this.form);

        await this.$axios.post(`/plans`, dataToSend);

        this.clearForm();

        this.$toast.success("Dados salvos com sucesso");

        this.afterOperation();
      } catch (error) {
        console.error(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        this.loading = true;

        const dataToSend = this.prepareDataTosend(this.form);

        await this.$axios.put(`/plans/${this.$route.params.id}`, dataToSend);

        this.$toast.success("Dados salvos com sucesso");

        this.afterOperation();
      } catch (error) {
        console.error(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    async loadFeatures() {
      try {
        this.loadingFeatures = true;

        const response = await this.$axios.get(`/plans/features`);

        this.setFeatures(response.data);
      } catch (error) {
        console.error(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loadingFeatures = false;
      }
    },
    setFeatures(data) {
      this.features = [...data];
    },
    async loadPlanData() {
      try {
        this.loadingFeatures = true;

        const response = await this.$axios.get(
          `/plans/${this.$route.params.id}`
        );

        this.setPlanData(response.data);
      } catch (error) {
        console.error(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loadingFeatures = false;
      }
    },

    setPlanData(data) {
      const cpData = { ...data };

      const { Features: features, name, value } = cpData;

      this.form.selectedFeatures = features.map((p) => p.id);
      this.form.name = name;
      this.form.value = value;
    },
    prepareDataTosend(data) {
      const cpData = JSON.parse(JSON.stringify(data));

      cpData.value = moneyHelper.parseDecimal(cpData.value);

      return cpData;
    },
    clearForm() {
      this.form = { selectedFeatures: [] };
    },
    afterOperation() {
      if (this.actionAfterOperation === "back") this.$router.go(-1);
    },
  },
  created() {
    this.loadFeatures();
    if (this.mod === "update") this.loadPlanData();
  },
};
</script>

<style></style>
