const helper = {
  parseDecimal(str) {
    //Se já for um número ou se não tiver virgula, não precisa converter
    if (typeof str === "number") return str;
    else if (!/,/.test(str)) return parseFloat(str);

    return parseFloat(str.replace(/\./g, "").replace(/,/g, "."));
  },
  formatCurrencyValue(value) {
    return value.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      style: "currency",
      currency: "BRL",
    });
  },
};

export default helper;
