<!-- LEMBRAR QUE SEMPRE PRECISA INICIALIZAR O V-MODEL NOS COMPONENTES QUE FOR UTILIZA-LO  -->

<template>
  <v-text-field
    :label="label"
    :value="valueAux"
    placeholder="0,00"
    @input="onInput"
    prefix="R$"
    :disabled="disabled"
    :solo="solo"
    :hide-details="hideDetails"
  ></v-text-field>
</template>

<script>
export default {
  props: ["value", "label", "solo", "hide-details", "disabled"],
  data() {
    return {
      empty: true,
    };
  },
  methods: {
    format(value) {
      const pattern = this.empty ? /[^\d.-]/g : /\D+/g;

      let cleanValue = parseFloat(String(value).replace(pattern, ""));

      if (cleanValue === 0 || isNaN(cleanValue)) return "0,00";

      let valueWithDecimalPoint;

      if (this.empty) {
        valueWithDecimalPoint = cleanValue;
      } else {
        valueWithDecimalPoint = cleanValue / 100;
      }

      const formated = valueWithDecimalPoint.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
      });

      this.empty = false;

      return formated;
    },
    onInput(event) {
      this.$emit("input", this.format(event));
    },
  },
  computed: {
    valueAux() {
      return this.empty ? this.format(this.value) : this.value;
    },
  },
};
</script>

<style>
</style>